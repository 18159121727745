import { Link } from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';
import { Fragment, useContext } from 'react';
import { Button } from '@mui/material';

const NavLinks = (props) => {
  const auth = useContext(AuthContext);

  return (
    <Fragment>
      {auth.isLoggedIn && (
        <Button color="inherit" component={Link} to="clientes">
          CLIENTES
        </Button>
      )}
      {auth.isLoggedIn && (
        <Button color="inherit" component={Link} to="items">
          ITEMS
        </Button>
      )}
      {auth.isLoggedIn && (
        <Button color="inherit" component={Link} to="horas">
          HORAS
        </Button>
      )}
      {auth.isLoggedIn && (
        <Button color="inherit" component={Link} to="reporte-horas">
          REPORTE HORAS
        </Button>
      )}
      {!auth.isLoggedIn && (
        <Button color="inherit" component={Link} to="/auth">
          LOGIN
        </Button>
      )}
      {auth.isLoggedIn && (
        <Button color="inherit" component={Link} to="/" onClick={auth.logout}>
          SALIR
        </Button>
      )}
    </Fragment>
  );
};

export default NavLinks;

import React, { Fragment } from 'react';
import useFetchData from '../../shared/hooks/use-fetch-data';

import ItemsList from '../../items/components/ItemList';

const Items = () => {
  const { data, loading } = useFetchData({
    method: 'get',
    url: 'items'
  });

  return (
    <Fragment>
      {loading && <div>Loading</div>}
      {!loading && <ItemsList items={data} />}
    </Fragment>
  );
};

export default Items;

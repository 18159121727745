import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchData = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let config = {
      method: props.method,
      url: props.url
    };

    if (props.data) {
      config.data = props.data;
    }

    const fetchData = async () => {
      try {
        const { data: response } = await axios.request(config);
        setData(response);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    fetchData();
  }, [props.method, props.url, props.data]);

  return {
    data,
    loading
  };
};

export default useFetchData;

import { Alert, Autocomplete, Button, Snackbar, Stack, TextField } from '@mui/material';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React from 'react';
import { Fragment, useEffect, useState } from 'react';
import * as yup from 'yup';

const Horas = () => {
  const [dataCustomers, setDataCustomers] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const validationSchema = yup.object().shape({
    horas: yup.number().moreThan(0, 'Elija cantidad de horas mayor a 0').required('Ingrese horas'),
    costo: yup.number().min(0, 'Elija un costo base mayor o igual a 0').required('Ingrese costo base'),
    dia: yup.date().required('Ingrese fecha'),
    observaciones: yup.string(),
    item: yup
      .object()
      .shape({
        id: yup.number().min(1, 'Elija un item').required('Ingrese item')
      })
      .required(),
    customer: yup
      .object()
      .shape({
        id: yup.number().min(1, 'Elija un cliente').required('Ingrese cliente')
      })
      .required()
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await axios.request({
          method: 'get',
          url: 'customers'
        });
        setDataCustomers(response);
      } catch (error) {
        console.error(error);
      }

      try {
        const { data: response } = await axios.get('items');
        setDataItems(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Fragment>
      <Formik
        initialValues={{
          item: { name: '', id: 0 },
          customer: { name: '', id: 0 },
          horas: '',
          costo: '',
          dia: '',
          observaciones: ''
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          const data = {
            customerId: values.customer.id,
            itemId: values.item.id,
            horas: values.horas,
            costo: values.costo,
            dia: new Date(values.dia).toISOString(),
            observaciones: values.observaciones
          };

          try {
            await axios.post('horas', data);
            setOpen(true);
            resetForm();
          } catch (err) {
            console.log(err);
            setOpenError(true);
          }
        }}>
        {({ values, setFieldValue, touched, errors, handleChange }) => (
          <Form>
            <Stack spacing={1}>
              <Autocomplete
                id="customer"
                value={values.customer}
                getOptionLabel={(d) => d?.name || ''}
                options={dataCustomers}
                sx={{ width: 300 }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, value) => {
                  setFieldValue('customer', value || { name: '', id: 0 });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cliente"
                    variant="standard"
                    error={touched.customer && Boolean(errors.customer)}
                    helperText={touched.customer && errors.customer && errors.customer.id}
                  />
                )}
              />
              <Autocomplete
                id="item"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={values.item}
                getOptionLabel={(d) => d?.name || ''}
                options={dataItems}
                sx={{ width: 300 }}
                onChange={(e, value) => {
                  setFieldValue('costo', value?.costoBase || '');
                  setFieldValue('item', value || { name: '', id: 0 });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Item"
                    variant="standard"
                    error={touched.item && Boolean(errors.item)}
                    helperText={touched.item && errors.item && errors.item.id}
                  />
                )}
              />
              <TextField
                id="dia"
                name="dia"
                label="Dia"
                value={values.dia}
                type="date"
                variant="standard"
                sx={{ width: 300 }}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={handleChange}
                error={touched.dia && Boolean(errors.dia)}
                helperText={touched.dia && errors.dia}
              />
              <TextField
                id="horas"
                name="horas"
                label="Horas"
                value={values.horas}
                InputLabelProps={{
                  shrink: true
                }}
                type="number"
                variant="standard"
                sx={{ width: 300 }}
                onChange={handleChange}
                error={touched.horas && Boolean(errors.horas)}
                helperText={touched.horas && errors.horas}
              />
              <TextField
                id="costo"
                name="costo"
                label="Valor hora"
                value={values.costo}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                sx={{ width: 300 }}
                onChange={handleChange}
                error={touched.costo && Boolean(errors.costo)}
                helperText={touched.costo && errors.costo}
              />
              <TextField
                  id="observaciones"
                  name="observaciones"
                  label="Observaciones"
                  value={values.observaciones}
                  type="string"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: 300 }}
                  onChange={handleChange}
                  error={touched.observaciones && Boolean(errors.observaciones)}
                  helperText={touched.observaciones && errors.observaciones}
              />
              <div>
                <Button type="submit" variant="contained">
                  Agregar
                </Button>
              </div>
            </Stack>
          </Form>
        )}
      </Formik>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Horas agregadas!
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          Hubo un problema al agregar horas!
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default Horas;

import { Box, AppBar, Toolbar } from '@mui/material';
import React from 'react';

const MainHeader = (props) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          {props.children}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MainHeader;

import MainHeader from './MainHeader';
import './MainNavigation.css';
import NavLinks from './NavLink';
import SideDrawer from './SideDrawer';
import React, { useState } from 'react';
import Backdrop from '../../UIElements/Backdrop';
import { Typography } from '@mui/material';

const MainNavigation = (props) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawer = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawer = () => {
    setDrawerIsOpen(false);
  };

  return (
    <React.Fragment>
      {drawerIsOpen && <Backdrop onClick={closeDrawer} />}
      <SideDrawer show={drawerIsOpen} onClick={closeDrawer}>
        <nav className="main-navigation__drawer-nav">
          <NavLinks />
        </nav>
      </SideDrawer>
      <MainHeader>
        <button className="main-navigation__menu-btn" onClick={openDrawer}>
          <span />
          <span />
          <span />
        </button>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Horas
        </Typography>
        <NavLinks />
      </MainHeader>
    </React.Fragment>
  );
};

export default MainNavigation;

import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Items from './items/pages/Items';
import MainNavigation from './shared/components/Navigation/MainNavigation';
import Auth from './auth/Auth';
import { AuthContext } from './shared/context/auth-context';
import Customers from './customer/pages/Customers';
import localforage from 'localforage';
import axios from 'axios';
import Inicio from './inicio/Inicio';
import Horas from './customer/pages/Horas';
import ReporteHoras from './horas/pages/ReporteHoras';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState('');

  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASE_URL;

  useEffect(() => {
    (async function () {
      try {
        const loggedInUser = await localforage.getItem('user');
        setIsLoggedIn(loggedInUser !== null);
        setUser(loggedInUser);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const login = useCallback(() => {
    setIsLoggedIn(true);
  }, []);

  const logout = useCallback(() => {
    localforage.removeItem('user', (err) => {
      if (!err) {
        setUser(null);
        setIsLoggedIn(false);
      } else {
        console.log(err);
      }
    });
  }, []);

  let routes;

  if (isLoggedIn) {
    routes = (
      <Fragment>
        <Route path="/" element={<Inicio />}></Route>
        <Route path="/clientes" element={<Customers />}></Route>
        <Route path="/horas" element={<Horas />}></Route>
        <Route path="/items" element={<Items />}></Route>
        <Route path="/reporte-horas" element={<ReporteHoras />}></Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Fragment>
    );
  } else {
    routes = (
      <Fragment>
        <Route path="/auth" element={<Auth />}></Route>
        <Route path="*" element={<Navigate to="/auth" replace />} />
      </Fragment>
    );
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      <Router>
        <MainNavigation user={user} />
        <main>
          <Routes>{routes}</Routes>
        </main>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;

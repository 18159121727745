import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField
} from '@mui/material';
import { DataGrid, GridActionsCellItem, GridFooterContainer, GridPagination, GridToolbar } from '@mui/x-data-grid';
import React, { Fragment, useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import PaymentIcon from '@mui/icons-material/Payment';
import DeleteIcon from '@mui/icons-material/Delete';
import ActualizarIcon from '@mui/icons-material/Update';
import PropTypes from 'prop-types';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export function TotalComponent(props) {
  return (
    <h4>
      Total: {props.total} - Total horas: {props.totalHoras}
    </h4>
  );
}

function CustomFooter(props) {
  return (
    <GridFooterContainer>
      <TotalComponent total={props.total} totalHoras={props.totalHoras} />
      <GridPagination />
    </GridFooterContainer>
  );
}

CustomFooter.propTypes = {
  total: PropTypes.number
};

const ReporteHoras = (props) => {
  const [snackbar, setSnackbar] = useState(null);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rows, setRows] = useState(props.items);
  const [dataCustomers, setDataCustomers] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [pagadoId, setPagadoId] = useState(null);
  const [borradoId, setBorradoId] = useState(null);
  const [actualizarId, setActualizarId] = useState(null);
  const [total, setTotal] = useState(0);
  const [totalHoras, setTotalHoras] = useState(0);
  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);

  const handleClose = () => {
    setPagadoId(null);
    setBorradoId(null);
    setActualizarId(null);
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: response } = await axios.request({
          method: 'get',
          url: 'customers'
        });
        response.push({ name: 'כולם', id: -1 });
        setDataCustomers(response);
        const { data: items } = await axios.request({
          method: 'get',
          url: 'items'
        });
        items.push({ name: 'כולם', id: -1 });
        setDataItems(items);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const handleCloseSnackbar = () => setSnackbar(null);

  const columns = [
    { field: 'id', headerName: 'ID', width: 40, hide: true },
    {
      field: 'customer_name',
      headerName: 'Cliente ',
      width: 120,
      editable: false
    },
    {
      field: 'item_name',
      headerName: 'Item ',
      width: 120,
      editable: false
    },
    {
      field: 'horas',
      headerName: 'Horas',
      width: 70,
      type: 'number',
      editable: false
    },
    {
      field: 'costo',
      headerName: 'Costo',
      width: 80,
      type: 'number',
      editable: false
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 80,
      type: 'number',
      valueGetter: (params) => params.row.horas * params.row.costo,
      editable: false
    },
    {
      field: 'dia',
      headerName: 'Dia',
      width: 120,
      editable: false,
      type: 'date',
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString('he-HE');
      }
    },
    {
      field: 'observaciones',
      headerName: 'Observaciones',
      width: 180,
      editable: false,
      type: 'string',
    },
    {
      field: 'pagado',
      headerName: 'Pagado',
      width: 80,
      editable: true,
      type: 'boolean'
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Acciones',
      width: 100,
      cellClassName: 'actions',
      getActions: (data) => {
        return [
          <GridActionsCellItem
            icon={<PaymentIcon />}
            disabled={data.row.pagado}
            label="Marcar pagado"
            className="textPrimary"
            onClick={marcarPagadoDialog(data.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            disabled={data.row.pagado}
            label="Delete"
            onClick={handleDeleteClick(data.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<ActualizarIcon />}
            disabled={parseInt(data.row.costo) === parseInt(data.row.item_costo)}
            label="Actualizar costo item"
            className="textPrimary"
            onClick={actualizarCostoDialog(data.id)}
            color="inherit"
          />
        ];
      }
    }
  ];

  const marcarPagado = () => {
    const pagar = async () => {
      await axios.put(`horas/${pagadoId}/pagado`);
      setDataLoaded(false);
      setPagadoId(null);
    };
    pagar();
  };

  const actualizarCostoItem = () => {
    const actualizarItem = async () => {
      await axios.put(`horas/${actualizarId}/actualizar-costo`);
      setDataLoaded(false);
      setActualizarId(null);
    };
    actualizarItem();
  };

  const marcarPagadoDialog = (id) => () => {
    setPagadoId(id);
  };

  const actualizarCostoDialog = (id) => () => {
    setActualizarId(id);
  };

  const handleNewCustomer = async (customers) => {
    setSelectedCustomers((customers || []).map((item) => item.id));
  };

  const handleNewItem = async (items) => {
    setSelectedItems((items || []).map((item) => item.id));
  };

  const handleDeleteClick = (id) => () => {
    setBorradoId(id);
  };

  const borrar = () => {
    const borrarFunc = async () => {
      await axios.delete(`horas/${borradoId}`);
      setDataLoaded(false);
      setBorradoId(null);
    };
    borrarFunc();
  };

  const handleDesdeChange = (val) => {
    setFechaDesde(val);
  };

  const handleHastaChange = (val) => {
    setFechaHasta(val);
  };

  useEffect(() => {
    (async () => {
      if (selectedCustomers.length === 0 && selectedItems.length === 0) {
        return;
      }

      const params = new URLSearchParams();
      let hasAllCustomers = false;
      let hasAllItems = false;
      if (selectedCustomers) {
        selectedCustomers.forEach((customerId) => {
          params.append('customerIds', customerId);
          hasAllCustomers = hasAllCustomers || customerId === -1;
        });
      }

      if (hasAllCustomers) {
        params.delete('customerIds');
      }

      if (selectedItems) {
        selectedItems.forEach((itemId) => {
          params.append('itemIds', itemId);
          hasAllItems = hasAllItems || itemId === -1;
        });
      }

      if (hasAllItems) {
        params.delete('itemIds');
      }

      if (fechaDesde) {
        params.append('fechaDesde', fechaDesde.format('YYYY-MM-DD'));
      }

      if (fechaHasta) {
        params.append('fechaHasta', fechaHasta.format('YYYY-MM-DD'));
      }

      const response = await axios.request({
        method: 'get',
        url: `horas`,
        params: params
      });

      setRows(response.data || []);
      setDataLoaded(true);
    })();
  }, [selectedCustomers, selectedItems, dataLoaded, fechaDesde, fechaHasta]);

  return (
    <Fragment>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' }
        }}
        noValidate
        autoComplete="off">
        <Autocomplete
          sx={{ display: 'inline', width: '30%' }}
          multiple
          disablePortal
          id="customerId"
          getOptionLabel={(d) => d.name}
          filterSelectedOptions
          options={dataCustomers}
          onChange={(e, values) => {
            setDataLoaded(false);
            handleNewCustomer(values);
          }}
          renderInput={(params) => <TextField {...params} label="Cliente" variant="standard" placeholder="Clientes" />}
        />
        <Autocomplete
          sx={{ display: 'inline', width: '30%' }}
          multiple
          disablePortal
          id="itemId"
          filterSelectedOptions
          getOptionLabel={(d) => d.name}
          options={dataItems}
          onChange={(e, values) => {
            setDataLoaded(false);
            handleNewItem(values);
          }}
          renderInput={(params) => <TextField {...params} label="Item" variant="standard" placeholder="Items" />}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Fecha desde"
            inputFormat="DD/MM/YYYY"
            value={fechaDesde}
            onChange={handleDesdeChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <DesktopDatePicker
            label="Fecha hasta"
            inputFormat="DD/MM/YYYY"
            value={fechaHasta}
            onChange={handleHastaChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          height: 650,
          padding: 0,
          width: '100%',
          '& .actions': {
            color: 'text.secondary'
          },
          '& .textPrimary': {
            color: 'text.primary'
          }
        }}>
        {!dataLoaded && <h6>Seleccione un cliente</h6>}
        {dataLoaded && (
          <DataGrid
            headerHeight={36}
            rowHeight={36}
            rows={rows}
            slots={{ toolbar: GridToolbar, footer: CustomFooter }}
            slotProps={{ footer: { total, totalHoras } }}
            columns={columns}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onStateChange={(state) => {
              const visibleRows = state.visibleRowsLookup;
              let visibleItems = [];
              for (const [id, value] of Object.entries(visibleRows)) {
                if (value === true) {
                  visibleItems.push(parseInt(id));
                }
              }
              const res = rows.filter((item) => visibleItems.includes(item.id));
              const total = res
                .map((item) => parseFloat(item.costo) * parseFloat(item.horas))
                .reduce((a, b) => a + b, 0);
              const totalHoras = res.map((item) => parseFloat(item.horas)).reduce((a, b) => a + b, 0);
              setTotal(total);
              setTotalHoras(totalHoras);
            }}
          />
        )}
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}>
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
      <Dialog open={pagadoId !== null} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{'Marcar pagado?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Margar Pagado?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button onClick={marcarPagado} autoFocus>
            Si
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={actualizarId !== null} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{'Actualizar costo item?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Actualizar costo?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button onClick={actualizarCostoItem} autoFocus>
            Si
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={borradoId !== null} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{'Borrar?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Confirma borrado?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button onClick={borrar} autoFocus>
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ReporteHoras;

import React, { useContext } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField, Button, Stack } from '@mui/material';
import { AuthContext } from '../shared/context/auth-context';
import axios from 'axios';
import localforage from 'localforage';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Ingrese un email valido')
    .required('Se requiere email'),
  password: yup
    .string()
    .min(8, 'La password tiene que tener al menos 8 caracteres')
    .required('Se requiere email')
});

const Auth = () => {
  const auth = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const user = {
        email: values.email,
        password: values.password
      };

      try {
        const response = await axios.post('login', user);
        localforage.setItem('user', response.data);

        auth.login();
      } catch (err) {
        console.log(err);
        alert('usuario o clave invalidos');
      }
    }
  });

  return (
    <div>
      <Stack
        component="form"
        noValidate
        spacing={1}
        onSubmit={formik.handleSubmit}
      >
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          variant="standard"
          sx={{ width: 300 }}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          variant="standard"
          sx={{ width: 300 }}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <div>
          <Button
            color="primary"
            variant="contained"
            sx={{ width: 300 }}
            type="submit"
          >
            Login
          </Button>
        </div>
      </Stack>
    </div>
  );
};

export default Auth;

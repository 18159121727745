import React, { Fragment } from 'react';
import useFetchData from '../../shared/hooks/use-fetch-data';

import CustomerList from '../components/CustomerList';

const Customers = () => {
  const { data, loading } = useFetchData({
    method: 'get',
    url: 'customers'
  });

  return (
    <Fragment>
      {loading && <div>Loading</div>}
      {!loading && <CustomerList items={data} />}
    </Fragment>
  );
};

export default Customers;
